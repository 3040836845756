import {Component} from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer clearfix">
            <a >
                <img alt="logo-colored" src="assets/layout/images/imagesFoot.png" />
                
            </a>
            <span class="footer-text-right" style="font-size:12px;padding:0px">eSAM Version:2.0.0</span>
           <!-- <span class="footer-text-right">
                <span class="material-icons">copyright</span>
                <span>All Rights Reserved</span>
            </span>-->
        </div>
    `
})
export class AppFooterComponent {

}
